import './App.css';
import Graph from "./pages/Graph";
import LogViewer from "./pages/logViewer";


import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< Graph />} />
          <Route path="/logs" element={< LogViewer />} />



        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
