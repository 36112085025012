import React, { useState, useEffect } from 'react';

const LogViewer = () => {
  

  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [ticketStats, setTicketStats] = useState({
    lastTicketTime: null,
    totalTicketsResold: null,
    lastTicketPrice: null,
    lastTicketInfo: null,
    ticketsAvailable: false,
    lastTicketFoundTime: null,
    lastTicketDetails: null
  });
  
  // Cron schedule state
  const [currentSchedule, setCurrentSchedule] = useState('');
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [scheduleError, setScheduleError] = useState(null);
  const [scheduleSuccess, setScheduleSuccess] = useState(false);
  const [showScheduleSettings, setShowScheduleSettings] = useState(false);
  
  // Predefined schedule options
  const scheduleOptions = [
    { value: '*/1 * * * *', label: 'Every minute' },
    { value: '*/5 * * * *', label: 'Every 5 minutes' },
    { value: '*/10 * * * *', label: 'Every 10 minutes' },
    { value: '*/15 * * * *', label: 'Every 15 minutes' },
    { value: '*/30 * * * *', label: 'Every 30 minutes' },
    { value: '0 * * * *', label: 'Every hour' },
    { value: '0 */2 * * *', label: 'Every 2 hours' },
    { value: '0 */6 * * *', label: 'Every 6 hours' },
    { value: '0 */12 * * *', label: 'Every 12 hours' },
    { value: '0 0 * * *', label: 'Once daily (midnight)' }
  ];
  const isAuthenticated = true;

  const MAX_LINES = 50; // Maximum number of log lines to display

  const fetchLogData = async () => {
    setLoading(true);
    try {
      // Replace with your actual API endpoint that serves the log file
      const response = await fetch('https://el.maxn.se/logs');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const text = await response.text();
      
      // Split by newlines and parse into structured format
      const lines = text.split('\n').filter(line => line.trim());
      const parsedLines = lines.map(line => {
        // Parse timestamp and log level if available
        const timestampMatch = line.match(/^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3})/);
        const levelMatch = line.match(/- (INFO|WARNING|ERROR|DEBUG) -/);
        
        return {
          timestamp: timestampMatch ? timestampMatch[1] : null,
          level: levelMatch ? levelMatch[1] : null,
          content: line
        };
      });
      
      // Reverse the array to show newest logs first and limit to MAX_LINES
      const limitedLines = [...parsedLines].reverse().slice(0, MAX_LINES);
      
      setLogData(limitedLines);
      setLastUpdated(new Date());
      extractTicketInfo(text, parsedLines);
      setError(null);
    } catch (err) {
      setError(`Failed to load log data: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the current cron schedule
  const fetchCurrentSchedule = async () => {
    try {
      const response = await fetch('https://el.maxn.se/cron/schedule');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCurrentSchedule(data.schedule);
    } catch (err) {
      console.error('Error fetching cron schedule:', err);
      setScheduleError('Failed to load current schedule');
    }
  };

  // Update the cron schedule
  const updateSchedule = async (newSchedule) => {
    setScheduleLoading(true);
    setScheduleError(null);
    setScheduleSuccess(false);
    
    try {
      // Get token from localStorage if available
      const token = prompt('code');
      
      const response = await fetch('https://el.maxn.se/cron/schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({ schedule: newSchedule }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      setCurrentSchedule(data.schedule);
      setScheduleSuccess(true);
      
      // Auto-hide success message after 3 seconds
      setTimeout(() => {
        setScheduleSuccess(false);
      }, 3000);
      
    } catch (err) {
      console.error('Error updating cron schedule:', err);
      setScheduleError(err.message);
    } finally {
      setScheduleLoading(false);
    }
  };
  const extractTicketInfo = (logText, parsedLines) => {
    // For the total tickets resold, we want the most recent information
    // First, reverse the original log order for processing to find the most recent match
    const reversedFullText = parsedLines.slice().reverse();
    
    // Look for the most recent "total tickets resold" message
    let totalTicketsResold = null;
    for (const line of reversedFullText) {
      const resoldMatch = line.content.match(/Der er indtil videre blevet videresolgt (\d+) billetter/);
      if (resoldMatch) {
        totalTicketsResold = resoldMatch[1];
        break; // Stop at the first (most recent) match
      }
    }
    
    // Try to find the last ticket listing time
    const ticketListingMatches = [...logText.matchAll(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}).*Found (\d+) ticket/g)];
    const lastTicketListing = ticketListingMatches.length > 0 ? 
      ticketListingMatches[ticketListingMatches.length - 1] : null;
    
    // Try to find ticket price information
    const priceMatch = logText.match(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}).*Ticket price: (\d+)/);
    
    // Look for any interesting ticket information
    const ticketInfoMatch = logText.match(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}).*ticket.*?:(.+?)(?=\n|$)/i);
    
    // Check for ticket availability only in the latest log entry
    let ticketsAvailable = false;
    let lastTicketFoundTime = null;
    let lastTicketDetails = null;
    
    // Get the most recent (first) log entry from the reversed array
    if (reversedFullText.length > 0) {
      const latestLogEntry = reversedFullText[0];
      
      // Check if the latest entry indicates available tickets
      if (latestLogEntry.content.includes('TICKETS AVAILABLE') || 
          (latestLogEntry.level === 'WARNING' && latestLogEntry.content.includes('ticket'))) {
        ticketsAvailable = true;
        lastTicketFoundTime = latestLogEntry.timestamp;
        
        // Try to extract the details about the available ticket from the latest entry
        const lineIndex = parsedLines.length - 1;
        
        // Look at surrounding lines for context (previous and next)
        const contextLines = parsedLines.slice(Math.max(0, lineIndex - 3), Math.min(parsedLines.length, lineIndex + 3));
        
        // Extract ticket details from the context
        for (const contextLine of contextLines) {
          if (contextLine.content.includes('DKK') || contextLine.content.includes('Broloppet')) {
            // This line likely contains ticket details
            const contentWithoutTimestamp = contextLine.content.replace(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3} - \w+ - /, '');
            lastTicketDetails = contentWithoutTimestamp;
            break;
          }
        }
      }
    }
    
    setTicketStats({
      lastTicketTime: lastTicketListing ? lastTicketListing[1] : null,
      totalTicketsResold,
      lastTicketPrice: priceMatch ? priceMatch[2] : null,
      lastTicketInfo: ticketInfoMatch ? ticketInfoMatch[2].trim() : null,
      ticketsAvailable,
      lastTicketFoundTime,
      lastTicketDetails
    });
  };
  useEffect(() => {
    fetchLogData();
    fetchCurrentSchedule();
    
    // Set up auto-refresh if enabled
    let intervalId;
    if (autoRefresh) {
      intervalId = setInterval(fetchLogData, 30000); // Refresh every 30 seconds
    }
    
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [autoRefresh]);

  // Function to get appropriate color for log level
  const getLevelStyle = (level) => {
    switch (level) {
      case 'ERROR':
        return { color: '#e53e3e' }; // red
      case 'WARNING':
        return { color: '#dd6b20' }; // orange
      case 'INFO':
        return { color: '#3182ce' }; // blue
      case 'DEBUG':
        return { color: '#718096' }; // gray
      default:
        return {};
    }
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return 'Unknown';
    // Extract date and time from the log timestamp format
    const match = timestamp.match(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/);
    if (match) {
      return `${match[1]} at ${match[2]}`;
    }
    return timestamp;
  };

  // Get human-readable label for current schedule
  const getCurrentScheduleLabel = () => {
    const option = scheduleOptions.find(opt => opt.value === currentSchedule);
    return option ? option.label : currentSchedule;
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      
      <div style={{ 
        border: '1px solid #e2e8f0', 
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: '20px'
      }}>
        <div style={{ 
          padding: '16px 24px',
          borderBottom: '1px solid #e2e8f0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#f8fafc'
        }}>
          <h1 style={{ margin: 0, fontSize: '1.5rem', fontWeight: 600 }}>Scraper Log Viewer</h1>
          <div style={{ display: 'flex', gap: '8px' }}>
            { (
              <button 
                onClick={() => setShowScheduleSettings(!showScheduleSettings)}
                style={{
                  padding: '8px 16px',
                  border: '1px solid #e2e8f0',
                  borderRadius: '4px',
                  background: showScheduleSettings ? '#e2e8f0' : 'transparent',
                  cursor: 'pointer'
                }}
              >
                ⏱️ Schedule Settings
              </button>
            )}
            <button 
              onClick={() => setAutoRefresh(!autoRefresh)}
              style={{
                padding: '8px 16px',
                border: '1px solid #e2e8f0',
                borderRadius: '4px',
                background: autoRefresh ? '#e2e8f0' : 'transparent',
                cursor: 'pointer'
              }}
            >
              {autoRefresh ? "Auto-refresh On" : "Auto-refresh Off"}
            </button>
            <button 
              onClick={fetchLogData}
              disabled={loading}
              style={{
                padding: '8px 16px',
                border: '1px solid #3182ce',
                borderRadius: '4px',
                background: '#3182ce',
                color: 'white',
                cursor: loading ? 'not-allowed' : 'pointer',
                opacity: loading ? 0.7 : 1
              }}
            >
              {loading ? "Refreshing..." : "Refresh Now"}
            </button>
          </div>
        </div>

        {/* Cron Schedule Settings Panel */}
        {showScheduleSettings && isAuthenticated && (
          <div style={{ 
            padding: '16px 24px',
            borderBottom: '1px solid #e2e8f0',
            background: '#fffaf0', // Light orange background
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
              <h2 style={{ fontSize: '1.25rem', margin: 0 }}>⚙️ Scheduler Settings</h2>
              <div style={{ fontSize: '0.875rem', color: '#4a5568' }}>
                Current schedule: <strong>{getCurrentScheduleLabel()}</strong>
              </div>
            </div>

            {scheduleError && (
              <div style={{ 
                padding: '12px', 
                background: '#fed7d7', 
                color: '#c53030', 
                borderRadius: '4px',
                marginBottom: '12px'
              }}>
                {scheduleError}
              </div>
            )}

            {scheduleSuccess && (
              <div style={{ 
                padding: '12px', 
                background: '#c6f6d5', 
                color: '#2f855a', 
                borderRadius: '4px',
                marginBottom: '12px'
              }}>
                Schedule updated successfully!
              </div>
            )}

            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
              gap: '12px',
              marginTop: '16px'
            }}>
              {scheduleOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => updateSchedule(option.value)}
                  disabled={scheduleLoading || option.value === currentSchedule}
                  style={{
                    padding: '12px',
                    border: option.value === currentSchedule 
                      ? '2px solid #4299e1' 
                      : '1px solid #e2e8f0',
                    borderRadius: '4px',
                    background: option.value === currentSchedule ? '#ebf8ff' : 'white',
                    cursor: option.value === currentSchedule ? 'default' : 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    opacity: scheduleLoading ? 0.7 : 1
                  }}
                >
                  <span style={{ fontWeight: 'bold', marginBottom: '4px' }}>{option.label}</span>
                  <span style={{ fontSize: '0.75rem', color: '#718096' }}>{option.value}</span>
                </button>
              ))}

              {/* Custom schedule option will be implemented here if needed */}
            </div>

            <div style={{ marginTop: '16px', fontSize: '0.875rem', color: '#718096' }}>
              Note: Changing the schedule will take effect immediately. The scraper will run according to the new schedule.
            </div>
          </div>
        )}

        {/* Ticket Availability Alert */}
        {ticketStats.ticketsAvailable && (
          <div style={{ 
            padding: '16px 24px',
            borderBottom: '1px solid #e2e8f0',
            background: '#fed7dd',
            animation: 'pulse 2s infinite'
          }}>
            <style dangerouslySetInnerHTML={{ __html: `
              @keyframes pulse {
                0% { background-color: #fed7dd; }
                50% { background-color: #fecdd3; }
                100% { background-color: #fed7dd; }
              }
            `}} />
            
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{ fontSize: '2rem', marginRight: '12px' }}>🚨</span>
              <h2 style={{ fontSize: '1.5rem', margin: 0, color: '#e11d48' }}>TICKETS AVAILABLE!</h2>
            </div>
            
            <div style={{
              background: 'white',
              padding: '16px',
              borderRadius: '8px',
              border: '2px solid #e11d48',
              marginTop: '10px'
            }}>
              <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px' }}>
                Last detected at:
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '1.125rem', marginBottom: '10px' }}>
                {ticketStats.lastTicketFoundTime ? formatDateTime(ticketStats.lastTicketFoundTime) : 'Unknown time'}
              </div>
              
              {ticketStats.lastTicketDetails && (
                <>
                  <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px', marginTop: '10px' }}>
                    Ticket details:
                  </div>
                  <div style={{ 
                    fontFamily: 'monospace',
                    background: '#f8fafc',
                    padding: '10px',
                    borderRadius: '4px',
                    fontSize: '0.9rem',
                    whiteSpace: 'pre-wrap'
                  }}>
                    {ticketStats.lastTicketDetails}
                  </div>
                </>
              )}
              
              <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}>
                <a 
                  href="https://www.sportstiming.dk/event/6583/resale" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    background: '#e11d48',
                    color: 'white',
                    borderRadius: '6px',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    boxShadow: '0 4px 6px rgba(225, 29, 72, 0.2)'
                  }}
                >
                  Go to Ticket Site Now!
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Ticket Stats Section */}
        <div style={{ 
          padding: '16px 24px',
          borderBottom: '1px solid #e2e8f0',
          background: '#ebf8ff',
        }}>
          <h2 style={{ fontSize: '1.25rem', margin: '0 0 12px 0' }}>🎟️ Ticket Dashboard</h2>
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '20px'
          }}>
            <div style={{ 
              background: ticketStats.ticketsAvailable ? '#fef2f2' : 'white',
              border: ticketStats.ticketsAvailable ? '1px solid #f87171' : '1px solid transparent',
              padding: '16px', 
              borderRadius: '8px', 
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
            }}>
              <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px' }}>
                Ticket Status
              </div>
              <div style={{ 
                fontWeight: 'bold', 
                fontSize: '1.125rem',
                color: ticketStats.ticketsAvailable ? '#dc2626' : '#10b981'
              }}>
                {ticketStats.ticketsAvailable ? '⚠️ Tickets Available!' : '✅ No tickets for sale'}
              </div>
            </div>
            
            <div style={{ 
              background: 'white', 
              padding: '16px', 
              borderRadius: '8px', 
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
            }}>
              <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px' }}>
                Last Ticket Activity
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                {ticketStats.lastTicketTime ? formatDateTime(ticketStats.lastTicketTime) : 'No data available'}
              </div>
            </div>
            
            <div style={{ 
              background: 'white', 
              padding: '16px', 
              borderRadius: '8px', 
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
            }}>
              <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px' }}>
                Total Tickets Resold
              </div>
              <div style={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                {ticketStats.totalTicketsResold ? ticketStats.totalTicketsResold : 'No data available'}
              </div>
            </div>
            
            {ticketStats.lastTicketPrice && (
              <div style={{ 
                background: 'white', 
                padding: '16px', 
                borderRadius: '8px', 
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
              }}>
                <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '4px' }}>
                  Last Ticket Price
                </div>
                <div style={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                  {ticketStats.lastTicketPrice} kr
                </div>
              </div>
            )}
          </div>
        </div>

        <div style={{ padding: '20px' }}>
          {error ? (
            <div style={{ 
              padding: '16px', 
              background: '#fed7d7', 
              color: '#c53030', 
              borderRadius: '4px' 
            }}>
              {error}
            </div>
          ) : (
            <>
              {lastUpdated && (
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  fontSize: '0.875rem', 
                  color: '#718096', 
                  marginBottom: '16px' 
                }}>
                  <p style={{ margin: 0 }}>Last updated: {lastUpdated.toLocaleTimeString()}</p>
                  <p style={{ margin: 0, fontStyle: 'italic' }}>Showing last {MAX_LINES} log entries (newest first)</p>
                </div>
              )}
              <div style={{ 
                border: '1px solid #2d3748',
                borderRadius: '4px',
                padding: '16px',
                background: '#1a202c',
                color: 'white',
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                overflowX: 'auto',
                maxHeight: '600px',
                overflowY: 'auto'
              }}>
                <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                  {loading ? (
                    <div style={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      padding: '16px' 
                    }}>
                      <span>Loading log data...</span>
                    </div>
                  ) : logData.length === 0 ? (
                    "No log data available"
                  ) : (
                    logData.map((line, index) => {
                      // Highlight lines containing ticket availability alerts
                      const isTicketAlert = line.content.includes('TICKETS AVAILABLE') || 
                                          (line.level === 'WARNING' && line.content.toLowerCase().includes('ticket'));
                      
                      return (
                        <div 
                          key={index} 
                          style={{ 
                            padding: '4px 0', 
                            backgroundColor: isTicketAlert ? 'rgba(220, 38, 38, 0.1)' : 'transparent',
                            borderLeft: isTicketAlert ? '4px solid #dc2626' : 'none',
                            paddingLeft: isTicketAlert ? '8px' : '0',
                            margin: isTicketAlert ? '4px 0' : '0'
                          }}
                        >
                          {line.timestamp && (
                            <span style={{ color: '#a0aec0' }}>{line.timestamp} </span>
                          )}
                          {line.level && (
                            <span style={getLevelStyle(line.level)}>
                              - {line.level} -
                            </span>
                          )}
                          <span style={{ 
                            fontWeight: isTicketAlert ? 'bold' : 'normal'
                          }}> 
                            {line.content.replace(/^.*? - .*? - /, '')}
                          </span>
                        </div>
                      );
                    })
                  )}
                </pre>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogViewer;